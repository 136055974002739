<script lang="ts">
	import { LOGIN_ROUTE } from '$/routes/auth/login/route';
	import { browser } from '$app/environment';
	import { goto } from '$app/navigation';
	import Copyright from '$lib/components/Copyright.svelte';
	import SquareBrokingLogo from '$lib/components/SquareBrokingLogo.svelte';
	import { IS_MAIN_DOMAIN } from '$lib/globals/vite-constants';
	import {
		AsyncCurrentUser,
		AsyncGetServerInfo,
		type GetServerInfoQuery,
	} from '$lib/graphql/generated';
	import { appLinks } from '$lib/routing';
	import Sentry from '$lib/sentry';
	import { browserIsSupported } from '$lib/utils/browser-compatibility';
	import { onMount } from 'svelte';
	import { slide } from 'svelte/transition';

	let serverInfo: GetServerInfoQuery['serverInfo'] | null = null;

	async function redirect() {
		const userPromise = AsyncCurrentUser({ errorPolicy: 'ignore', fetchPolicy: 'network-only' });

		// Fetch server info
		serverInfo =
			(await AsyncGetServerInfo({ fetchPolicy: 'network-only' })).data.serverInfo ?? null;
		Sentry.setContext('Server', {
			version: serverInfo.version ?? 'unknown',
			isProduction: serverInfo.isProductionEnvironment,
		});

		// Fetch user
		const userScopes = (await userPromise).data.me?.user?.scopeTypes ?? null;
		let nextPage: string = LOGIN_ROUTE;
		if (userScopes !== null && userScopes.length > 0) {
			// Find the first scope that the user has access to, and redirect him there
			appLinkLoop: for (const appLink of appLinks)
				for (const userScope of userScopes)
					if (appLink.accessScopesSet?.has(userScope)) {
						nextPage = appLink.href;
						break appLinkLoop;
					}
		} else {
			Sentry.addBreadcrumb({
				category: 'user',
				message: 'User has no scopes or is not connected. Redirecting to login page.',
				level: 'info',
				data: { fetchedUserId: (await userPromise).data.me?.userId ?? null },
			});
		}
		void goto(nextPage);
	}

	onMount(() => {
		if (!browserIsSupported()) void goto('/unsupported-browser');
		else void redirect();
	});
</script>

<!-- Note: This page has the same layout as the `auth/login` page to allow for smooth transitionning -->
<div
	class="w-full h-screen md:min-h-md flex flex-col justify-end md:justify-between md:py-5"
	role="presentation"
>
	<!-- This Div is used for centering, and has the same height as the footer -->
	<div class="flex-grow-0 h-20 invisible" />

	<main class="flex-grow flex flex-col justify-center items-center">
		<h1
			class="text-[24px] md:(text-[32px] mb-10) leading-[40px] font-900 text-center font-extended"
		>
			<SquareBrokingLogo />
		</h1>
	</main>

	<footer class="flex-grow-0 h-20 w-full flex flex-col items-center justify-end text-sm <md:pb-5">
		<Copyright />
		{#if browser && !IS_MAIN_DOMAIN && serverInfo}
			<span class="opacity-35" transition:slide|local>
				{#if !serverInfo.isProductionEnvironment}Pre-{/if}Production server v{serverInfo.version}
			</span>
		{/if}
	</footer>

	<!-- The following div is here to add bottom padding on mobile -->
	<div class="md:hidden p-screen-bottom min-h-3 w-full" aria-hidden="true" />
</div>
